import { ValvesMainPageCardData } from "./valves-main-page.interfaces";

export const valvesCardsData: ValvesMainPageCardData[] = [
    {
        calcName: "hydraulic-calculator",
        isDisabled: false,
        titleKey: "valves_hydraulic_calculator_title",
        mobileTitleKey: "valves_hydraulic_calculator_title_mobile",
        textKey: "valves_hydraulic_calculator_text",
    },
    {
        calcName: "product-finder",
        isDisabled: false,
        titleKey: "valves_product_finder_title",
        mobileTitleKey: "valves_product_finder_title",
        textKey: "valves_product_finder_text",
    },
]